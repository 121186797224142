import Vue from 'vue'
import Router from 'vue-router'

import Vuex from 'vuex'
import VueResource from 'vue-resource'
import Notifications from 'vue-notification'
import VueTheMask from 'vue-the-mask'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const DashboardMobile = () => import('@/views/DashboardMobile')

const MaterialUnidade = () => import('@/views/tabelas/MaterialUnidade')
const MaterialTipo = () => import('@/views/tabelas/MaterialTipo')
const Material = () => import('@/views/tabelas/Material')
const MaterialMP = () => import('@/views/tabelas/MaterialMP')
const MaterialEM = () => import('@/views/tabelas/MaterialEM')
const MaterialST = () => import('@/views/tabelas/MaterialST')
const MaterialEQ = () => import('@/views/tabelas/MaterialEQ')
const MaterialMO = () => import('@/views/tabelas/MaterialMO')
const Cambio = () => import('@/views/tabelas/Cambio')
const Produto = () => import('@/views/tabelas/Produto')
const Preparo = () => import('@/views/tabelas/Preparo')
const ProdutoMaterial = () => import('@/views/tabelas/MaterialUso')
const ProdutoMaterialModal = () => import('@/views/tabelas/MaterialUsoModal')
const Encargo = () => import('@/views/tabelas/Encargo')
const maoDeObra = () => import('@/views/tabelas/Maodeobra')
const maoDeObraUso = () => import('@/views/tabelas/MaodeobraUso')
const maoDeObraUsoModal = () => import('@/views/tabelas/MaodeobraUsoModal')
const Anotacao = () => import('@/views/tabelas/Anotacao')
const Suporte = () => import('@/views/tabelas/Suporte')
const Precificador = () => import('@/views/tabelas/Precificador')
const PrecificadorPreparo = () => import('@/views/tabelas/PrecificadorPreparo')
const PrecificadorProduto = () => import('@/views/tabelas/PrecificadorProduto')
const ProdutoLinha = () => import('@/views/tabelas/ProdutoLinha')

const ConfigEncargo = () => import('@/views/configuracoes/Encargo')
const ConfigImposto = () => import('@/views/configuracoes/Imposto')
const ConfigUnidade = () => import('@/views/configuracoes/Unidade')
const ConfigUsuario = () => import('@/views/configuracoes/Usuario')
const ConfigOpcoes = () => import('@/views/configuracoes/Opcoes')
const ConfigTabelas = () => import('@/views/configuracoes/Tabelas')

const Login = () => import('@/views/Login')
const Login2 = () => import('@/views/Login2')
const Ajuda = () => import('@/views/tabelas/Ajuda')

const UsuariosListar = () => import('@/views/usuarios/Listar')
const UsuariosCriar = () => import('@/views/usuarios/Criar')
const UsuariosEditar = () => import('@/views/usuarios/Editar')

const UserListar = () => import('@/views/configuracoes/usuarios/Listar')
const UserCriar = () => import('@/views/configuracoes/usuarios/Criar')
const UserEditar = () => import('@/views/configuracoes/usuarios/Editar')

const GruposListar = () => import('@/views/usuarios/grupos/Listar')
const GruposCriar = () => import('@/views/usuarios/grupos/Criar')
const GruposEditar = () => import('@/views/usuarios/grupos/Editar')
const GruposDeletar = () => import('@/views/usuarios/grupos/Deletar')

const EmpresasListar = () => import('@/views/usuarios/empresas/Listar')
const EmpresasCriar = () => import('@/views/usuarios/empresas/Criar')
const EmpresasEditar = () => import('@/views/usuarios/empresas/Editar')
const EmpresasDeletar = () => import('@/views/usuarios/empresas/Deletar')

const RegrasListar = () => import('@/views/usuarios/regras/Listar')
const RegrasCriar = () => import('@/views/usuarios/regras/Criar')
const RegrasEditar = () => import('@/views/usuarios/regras/Editar')
const RegrasDeletar = () => import('@/views/usuarios/regras/Deletar')

const RegrasGruposListar = () => import('@/views/usuarios/regras_grupo/Listar')
const RegrasGruposCriar = () => import('@/views/usuarios/regras_grupo/Criar')
const RegrasGruposEditar = () => import('@/views/usuarios/regras_grupo/Editar')
const RegrasGruposDeletar = () => import('@/views/usuarios/regras_grupo/Deletar')

const UsuariosGruposListar = () => import('@/views/usuarios/usuarios_grupo/Listar')
const UsuariosGruposCriar = () => import('@/views/usuarios/usuarios_grupo/Criar')
const UsuariosGruposEditar = () => import('@/views/usuarios/usuarios_grupo/Editar')
const UsuariosGruposDeletar = () => import('@/views/usuarios/usuarios_grupo/Deletar')

const UsuariosAreasListar = () => import('@/views/usuarios/clientes_areas/Listar')
const UsuariosAreasCriar = () => import('@/views/usuarios/clientes_areas/Criar')
const UsuariosAreasEditar = () => import('@/views/usuarios/clientes_areas/Editar')
const UsuariosAreasDeletar = () => import('@/views/usuarios/clientes_areas/Deletar')

const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Register = () => import('@/views/pages/Register')

Vue.use(Router)

Vue.use(VueResource)
Vue.use(Vuex)
Vue.use(Notifications)
Vue.use(VueTheMask)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard/1',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/dashboard/:id?',
          name: 'Tabela de Preços',
          component: Dashboard,
          props: (route) => ({ id: route.params.id || 1 })
        },
        {
          path: '/dashboardmobile/:id?',
          name: 'Tabela de Preços (mobile)',
          component: DashboardMobile,
          props: (route) => ({ id: route.params.id || 1 })
        },
        {
          path: '/gerapreco/:id?',
          name: 'Gera Preço',
          component: Precificador,
          props: (route) => ({ id: route.params.id || 1 })
        },
        {
          path: '/ajuda',
          name: 'Ajuda',
          component: Ajuda
        },
        {
          path: '/tabelas',
          redirect: '/tabelas/material',
          name: 'Tabelas',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/tabelas/materia-prima',
              name: 'Matéria-Prima',
              component: MaterialMP
            },
            {
              path: '/tabelas/embalagem',
              name: 'Embalagens',
              component: MaterialEM
            },
            {
              path: '/tabelas/servico-terceiro',
              name: 'Serviços de Terceiros',
              component: MaterialST
            },
            {
              path: '/tabelas/equipamento',
              name: 'Equipamentos',
              component: MaterialEQ
            },
            {
              path: '/tabelas/mao-de-obra',
              name: 'Mão de Obra',
              component: MaterialMO
            },
            {
              path: '/tabelas/cambio',
              name: 'Câmbio',
              component: Cambio
            },
            {
              path: '/tabelas/produtosmateriais/:id',
              name: 'Insumos em Produtos',
              component: ProdutoMaterial
            },
            {
              path: '/tabelas/maodeobrauso/:id',
              name: 'Mao de Obra em Produtos',
              component: maoDeObraUso
            },
            {
              path: '/tabelas/produtosmateriaismodal/:id',
              name: 'Uso de Insumos',
              component: ProdutoMaterialModal
            },
            {
              path: '/tabelas/maodeobrausomodal/:id',
              name: 'Uso de Mao de Obra',
              component: maoDeObraUsoModal
            }
          ]
        },
        {
          path: 'precificador',
          name: 'Precificador',
          component: PrecificadorProduto
        },
        {
          path: 'preparo',
          name: 'Preparo',
          component: PrecificadorPreparo
        },
        {
          path: 'anotacoes',
          name: 'Anotações',
          component: Anotacao
        },
        {
          path: 'faq',
          name: 'Consultoria Web',
          component: Suporte
        },
        {
          path: '/configuracoes',
          redirect: '/configuracoes/usuarios',
          name: 'Configurações',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/configuracoes/opcoes',
              name: 'Opções',
              component: ConfigOpcoes
            },
            {
              path: '/configuracoes/usuarios/listar',
              name: 'Listar Usuários da Empresa',
              component: UserListar
            },
            {
              path: '/configuracoes/usuarios/criar',
              name: 'Criar Usuário da Empresa',
              component: UserCriar
            },
            {
              path: '/configuracoes/usuarios/editar/:id',
              name: 'Editar Usuário da Empresa',
              component: UserEditar
            },
            {
              path: '/configuracoes/impostos',
              name: 'Impostos',
              component: ConfigImposto
            },
            {
              path: '/configuracoes/encargos',
              name: 'Encargos',
              component: ConfigEncargo
            },
            {
              path: '/configuracoes/unidades',
              name: 'Unidades e Conversores',
              component: ConfigUnidade
            },
            {
              path: '/configuracoes/linha_produtos',
              name: 'Linha de Produtos',
              component: ProdutoLinha
            },
            {
              path: '/configuracoes/produtos',
              name: 'Produtos',
              component: Produto
            },
            {
              path: '/configuracoes/preparos',
              name: 'Preparos',
              component: Preparo
            },
            {
              path: '/configuracoes/tabelas',
              name: 'Renomear Tabelas',
              component: ConfigTabelas
            }
          ]
        },
        {
          path: '/admin',
          redirect: '/admin/usuarios/listar',
          name: 'Administrativo',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '/admin/usuarios/listar',
              name: 'Listar Usuários',
              component: UsuariosListar
            },
            {
              path: '/admin/usuarios/criar',
              name: 'Criar Usuário',
              component: UsuariosCriar
            },
            {
              path: '/admin/usuarios/editar/:id',
              name: 'Editar Usuário',
              component: UsuariosEditar
            },
            {
              path: '/admin/grupos/listar',
              name: 'Listar Grupos',
              component: GruposListar
            },
            {
              path: '/admin/empresas/listar',
              name: 'Listar Empresas',
              component: EmpresasListar
            },
            {
              path: '/admin/grupos/criar',
              name: 'Criar Grupo',
              component: GruposCriar
            },
            {
              path: '/admin/empresas/criar',
              name: 'Criar Empresa',
              component: EmpresasCriar
            },
            {
              path: '/admin/grupos/editar/:id',
              name: 'Editar Grupo',
              component: GruposEditar
            },
            {
              path: '/admin/empresas/editar/:id',
              name: 'Editar Empresa',
              component: EmpresasEditar
            },
            {
              path: '/admin/grupos/remover/:id',
              name: 'Remover Grupo',
              component: GruposDeletar
            },
            {
              path: '/admin/empresas/remover/:id',
              name: 'Remover Empresa',
              component: EmpresasDeletar
            },
            {
              path: '/admin/regras/listar',
              name: 'Listar Regras',
              component: RegrasListar
            },
            {
              path: '/admin/regras/criar',
              name: 'Criar Regra',
              component: RegrasCriar
            },
            {
              path: '/admin/regras/editar/:id',
              name: 'Editar Regra',
              component: RegrasEditar
            },
            {
              path: '/admin/regras/remover/:id',
              name: 'Remover Regra',
              component: RegrasDeletar
            },
            {
              path: '/admin/regras_grupo/listar',
              name: 'Listar Regras de Grupos',
              component: RegrasGruposListar
            },
            {
              path: '/admin/regras_grupo/criar',
              name: 'Criar Regra de Grupo',
              component: RegrasGruposCriar
            },
            {
              path: '/admin/regras_grupo/editar/:id',
              name: 'Editar Regra de Grupo',
              component: RegrasGruposEditar
            },
            {
              path: '/admin/regras_grupo/remover/:id',
              name: 'Remover Regra de Grupo',
              component: RegrasGruposDeletar
            },
            {
              path: '/admin/usuarios_grupo/listar',
              name: 'Listar Usuarios de Grupos',
              component: UsuariosGruposListar
            },
            {
              path: '/admin/usuarios_grupo/criar',
              name: 'Criar Usuarios de Grupos',
              component: UsuariosGruposCriar
            },
            {
              path: '/admin/usuarios_grupo/editar/:id',
              name: 'Editar Usuarios de Grupos',
              component: UsuariosGruposEditar
            },
            {
              path: '/admin/usuarios_grupo/remover/:id',
              name: 'Remover Usuarios de Grupos',
              component: UsuariosGruposDeletar
            },
            {
              path: '/admin/clientes_areas/listar',
              name: 'Listar Clientes de Áreas',
              component: UsuariosAreasListar
            },
            {
              path: '/admin/clientes_areas/criar',
              name: 'Criar Clientes de Áreas',
              component: UsuariosAreasCriar
            },
            {
              path: '/admin/clientes_areas/editar/:id',
              name: 'Editar Clientes de Áreas',
              component: UsuariosAreasEditar
            },
            {
              path: '/admin/clientes_areas/remover/:id',
              name: 'Remover Clientes de Áreas',
              component: UsuariosAreasDeletar
            }
          ]
        }
      ] // children
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/login2',
      name: 'Login2',
      component: Login2
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

