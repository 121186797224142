import 'core-js/stable'
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'

/* Extras */
import VueSession from 'vue-session'
import VueGoodTablePlugin from 'vue-good-table'
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-good-table/dist/vue-good-table.css'
import babelPolyfill from 'babel-polyfill'
import VueTour from 'vue-tour'
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
Vue.component('downloadExcel', JsonExcel)

require('vue-tour/dist/vue-tour.css')

Vue.config.performance = true
Vue.use(CoreuiVue)

Vue.use(VueSession)
Vue.use(Notifications)
Vue.use(VueGoodTablePlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2)
Vue.use(VueTour)

// Chave da API no DreamFactory
//var CHAVE_API = '1b203f04e80a69367c20439fb764b53f7bcbc6912e351f774d6e902b665c207c'
var CHAVE_API = 'c447466fe8a05454df9647ecc6f333ee2bb825c2260d8a7b7678a9e0a02fef48'

// URL DA API
var URL_API = 'https://api.prolucro.com.br/api/v2/'

// URL DO WEB HELPER (PHP)
var URL_APPAUX = 'https://web.prolucro.com.br/'

// PATH PADRAO DA APLICACAO
var PATH_API = 'prolucro/_table'

// PATH DO APP DE SUPORTE
var URL_SUPORTE = 'https://faq.prolucro.com.br/'

Vue.mixin({
  data: function () {
    return {
      get ChaveApi () {
        return CHAVE_API
      },
      get URLApi () {
        return URL_API
      },
      get URLApp () {
        return URL_APPAUX
      },
      get PATHApi () {
        return PATH_API
      },
      get URLSuporte () {
        return URL_SUPORTE
      },
      excecoes: ['gp_Grupo', 'gp_Grupo_Regra', 'gp_Grupo_Tipo', 'gp_Regra', 'gp_User', 'gp_User_Grupo', 'gpv_User', 
    'filtro_Grupo_Regra', 'filtro_Regra', 'filtro_User_Grupo', 'user', 'pro_Imposto', 'pro_Imposto_Item', 'filtro_Imposto']
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  methods: {
    // Confere permissao de acesso a um recurso
    permissao: function (p) {
      var perms = this.$session.get('regras')
      for (var i = 0; i < perms.length; i++) {
        if (perms[i].role_descricao === p) {
          return true
        }
        // Se tiver underline, permite o primeiro nivel tambem
        p = p.toString()
        if (p.indexOf('_') >= 0) {
          var tmp = p.split('_')
          if (typeof tmp[0] !== 'undefined' && perms[i].role_descricao === tmp[0]) {
            return true
          }
        }
      }
      return false
    },
    // Verifica o grupo do usuario logado
    permissao_grupo: function (p) {
      var perms = this.$session.get('regras')
      for (var i = 0; i < perms.length; i++) {
        if (perms[i].grp_ID === p || perms[i].grp_mnemonico === p) {
          return true
        }
        // Se tiver underline, permite o primeiro nivel tambem
        p = p.toString()
        if (p.indexOf('_') >= 0) {
          var tmp = p.split('_')
          if (typeof tmp[0] !== 'undefined' && perms[i].grp_mnemonico === tmp[0]) {
            return true
          }
        }
      }
      return false
    },
    // Verifica o grupo do usuario logado
    permissao_area: function () {
      // 1. Pega o grupo atual, a area na qual faz parte e se é coordenador
      var asAreas = []
      var osGrupos = []
      var asCoordArea = []
      var osClientes = []
      var isAdmin = false
      return this.listar('filtro_User_Grupo', 'grp_ID', '(grp_ativo = 1) AND (usr_ID = ' + this.$session.get('user_ID') + ')').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            if (response[i].gtipo_ID === 2) {
              asAreas.push(response[i].grp_ID)
            } else {
              osGrupos.push(response[i].grp_ID)
              asCoordArea.push(response[i].grp_coord_areas)
              if (response[i].grp_ID === 1) {
                isAdmin = true
              }
            }
          }
          // Se o perfil é admin, já da ok e libera (null = liberado)
          if (isAdmin) {
            return {isAdmin: true, clientes: null, usuarios: null, isCliente: false}
          }
          // 2. Busca os clientes que pertencem a esta area
          return this.listar('filtro_User_Grupo', 'grp_ID', 'grp_ID = 7').then(
            (response) => {
              var ehcliente = false
              for (var i = 0; i < response.length; i++) {
                osClientes.push(response[i].CLIENT_CONTACT_GENERAL_FK)
                if (this.$session.get('user_ID') === response[i].usr_ID) {
                  ehcliente = true
                }
              }
              // 3. Se sou coordenador, posso ver de qualquer usuario
              if (asCoordArea.includes(true)) {
                return {isAdmin: false, clientes: osClientes, usuarios: null, isCliente: false}
              } else {
                // Senão, vejo só o que é meu
                return {isAdmin: false, clientes: osClientes, usuarios: this.$session.get('user_ID'), isCliente: ehcliente}
              }
            },
            (response) => {
              return {isAdmin: false, clientes: null, usuarios: null, isCliente: false}
            }
          )
        },
        (response) => {
          return {isAdmin: false, clientes: null, usuarios: null, isCliente: false}
        }
      )
    },
    // Retorna um campo
    campo: function (tbl, fld, w, db) {
      var perms = this.$session.get('grupo')
      if (perms) {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID = ' + String(perms) + ')'
          } else {
            w = '(grp_ID = ' + String(perms) + ')'
          }
        }
      } else {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID IS NULL)'
          } else {
            w = '(grp_ID IS NULL)'
          }
        }
      }
      if (!db) { db = 'prolucro/_table' }
      return this.$http.get(this.URLApi + db + `/${tbl}?fields=${fld}&filter=` + encodeURIComponent(w), {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        var r = response.body.resource
        if (!r) { return response.body }
        return r[0][fld]
      }, (response) => {
        if (response.body.error.code === 401) {
          // alert('Tempo de sessao esgotado. Faça um novo login.')
          this.$router.push('/login2?redir=' + encodeURIComponent(document.location.href))
          document.location.reload()
        }
        return null
      })
    },
    esquema: function (tbl) {
      return this.$http.get(this.URLApi + 'prolucro/_schema' + `/${tbl}`, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        var r = response.body.resource
        if (!r) { return response.body }
        return r
      }, (response) => {
        if (response.body.error.code === 401) {
          // alert('Tempo de sessao esgotado. Faça um novo login.')
          this.$router.push('/login2?redir=' + encodeURIComponent(document.location.href))
          document.location.reload()
        }
        return null
      })
    },
    // Retorna um campo do usuario (custom)
    campoUsuario: function (fld, uid) {
      var db = 'user/custom'
      var url = this.URLApi + db + `/${fld}`
      if (this.$session.get('id') === uid) { uid = 0 }
      if (uid > 0) {
        db = 'system/user?related=user_custom_by_user_id'
        url = this.URLApi + db + `&filter=id%20%3D%20${uid}`
      }
      return this.$http.get(url, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (!uid) {
          return response.body
        } else {
          var r = response.body.resource[0].user_custom_by_user_id
          if (r) {
            for (var a = 0; a <= r.length; a++) {
              if (r[a].name === fld) {
                return r[a].value
              }
            }
          }
          return ''
        }
      }, (response) => {
        if (response.body.error.code === 401) {
          // alert('Tempo de sessao esgotado. Faça um novo login.')
          this.$router.push('/login2?redir=' + encodeURIComponent(document.location.href))
          document.location.reload()
        }
        return null
      })
    },
    // Atualiza um campo do usuario (custom)
    atualizaCampoUsuario: function (fld, v, uid) {
      var db = 'user/custom'
      var url = this.URLApi + db + `/${fld}`
      if (this.$session.get('id') === uid) { uid = 0 }
      if (uid > 0) {
        db = 'system/user?related=user_custom_by_user_id'
        url = this.URLApi + db + `&filter=id%20%3D%20${uid}`
        v = { resource: [ { 'user_custom_by_user_id': { 'name': fld, 'value': v } } ] }
      }
      return this.$http.patch(url, v, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        var r = response.body.resource
        if (!r) { return response.body }
        return r
      }, (response) => {
        if (response.body.error.code === 401) {
          // alert('Tempo de sessao esgotado. Faça um novo login.')
          this.$router.push('/login2?redir=' + encodeURIComponent(document.location.href))
          document.location.reload()
        }
        return null
      })
    },
    // Retorna uma linha
    campos: function (tbl, w, db) {
      var perms = this.$session.get('grupo')
      if (perms) {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID = ' + String(perms) + ')'
          } else {
            w = '(grp_ID = ' + String(perms) + ')'
          }
        }
      } else {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID IS NULL)'
          } else {
            w = '(grp_ID IS NULL)'
          }
        }
      }
      if (!db) { db = 'prolucro/_table' }
      if (w) { w = '?filter=' + encodeURIComponent(w) } else { w = '' }
      return this.$http.get(this.URLApi + db + `/${tbl}` + w, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        var r = response.body.resource
        if (!r) { return response.body }
        return r[0]
      }, (response) => {
        if (response.body.error.code === 401) {
          // alert('Tempo de sessao esgotado. Faça um novo login.')
          this.$router.push('/login2?redir=' + encodeURIComponent(document.location.href))
          document.location.reload()
        }
        return null
      })
    },
    // Retorna varias linhas
    listar: function (tbl, ordby, w, db, fields) {
      var perms = this.$session.get('grupo')
      if (perms) {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID = ' + String(perms) + ')'
          } else {
            w = '(grp_ID = ' + String(perms) + ')'
          }
        }
      } else {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID IS NULL)'
          } else {
            w = '(grp_ID IS NULL)'
          }
        }
      }
      if (!db) { db = 'prolucro/_table' }
      if (!w) { w = '' }
      if (isNaN(w)) { w = '?filter=' + encodeURIComponent(w) } else { w = (w > 0 ? '/' + w : '') }
      if (fields) { (w ? w += '&fields=' + encodeURIComponent(fields) : w = '?fields=' + encodeURIComponent(fields)) }
      if (ordby) { (w ? ordby = '&order=' + encodeURI(ordby) : ordby = '?order=' + encodeURI(ordby)) }
      return this.$http.get(this.URLApi + db + `/${tbl}` + w + ordby, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        var r = response.body.resource
        if (!r) { r = response.body }
        return r
      }, (response) => {
        if (response.body.error.code === 401) {
          // alert('Tempo de sessao esgotado. Faça um novo login.')
          this.$router.push('/login2?redir=' + encodeURIComponent(document.location.href))
          document.location.reload()
        }
        return []
      })
    },
    // Retorna a contagem de linhas apenas
    contar: function (tbl, w, db) {
      var perms = this.$session.get('grupo')
      if (perms) {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID = ' + String(perms) + ')'
          } else {
            w = '(grp_ID = ' + String(perms) + ')'
          }
        }
      } else {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID IS NULL)'
          } else {
            w = '(grp_ID IS NULL)'
          }
        }
      }
      if (!db) { db = 'prolucro/_table' }
      if (w) { w = '?filter=' + encodeURIComponent(w) + '&count_only=true' } else { w = '?count_only=true' }
      return this.$http.get(this.URLApi + db + `/${tbl}` + w, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        var r = response.body.resource
        if (!r) { r = response.body }
        return r
      }, (response) => {
        if (response.body.error.code === 401) {
          // alert('Tempo de sessao esgotado. Faça um novo login.')
          this.$router.push('/login2?redir=' + encodeURIComponent(document.location.href))
          document.location.reload()
        }
        return []
      })
    },
    // Inserir registro
    inserir: function (tbl, flds, db, msg) {
      var perms = this.$session.get('grupo')
      if (!db) { db = 'prolucro/_table' }
      var msgsuccess = msg
      if (!msgsuccess) { msgsuccess = 'Seu registro foi salvo com sucesso!' }
      return this.$http.post(this.URLApi + db + `/${tbl}`, flds, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        var tmp = response.body.resource
        if (perms && !this.excecoes.includes(tbl)) {
          // Atualiza o registro, incluindo o grp_ID
          var a = tmp[0]
          var w2 = '/' + a[Object.keys(a)[0]] // Pega o valor da chave primaria
          var flds2 = {grp_ID: perms}
          return this.$http.patch(this.URLApi + db + `/${tbl}` + w2, flds2, {
              headers: {
                'X-DreamFactory-Session-Token': this.$session.get('jwt'),
                'X-Dreamfactory-API-Key': CHAVE_API,
                'Content-Type': 'application/json'
              }
            }).then((r) => {
              if (msg !== '1') {
                this.$notify({
                  type: 'success',
                  title: 'Registro salvo',
                  text: msgsuccess
                })
              }
              return tmp[0]
            }, (r) => {
              this.$notify({
                type: 'danger',
                title: 'Falha ao atualizar grp_ID',
                text: 'Erro cód ' + r.body.error.code + ': ' + r.body.error.message
              })
              return null
            })
        } else {
          if (msg !== '1') {
            this.$notify({
              type: 'success',
              title: 'Registro salvo',
              text: msgsuccess
            })
          }
          return tmp[0]
        }
      }, (response) => {
        this.$notify({
          type: 'danger',
          title: 'Falha ao salvar',
          text: 'Erro cód ' + response.body.error.code + ': ' + response.body.error.message
        })
        return null
      })
    },
    // Atualizar registro
    atualizar: function (tbl, flds, id, db, msg) {
      if (!db) { db = 'prolucro/_table' }
      var w = ''
      if (isNaN(id)) { w = '?filter=' + encodeURIComponent(id) } else { w = '/' + id }
      var msgsuccess = msg
      if (!msgsuccess) { msgsuccess = 'Seu registro foi salvo com sucesso!' }
      return this.$http.patch(this.URLApi + db + `/${tbl}` + w, flds, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (msg !== '1') {
          this.$notify({
            type: 'success',
            title: 'Registro atualizado',
            text: msgsuccess
          })
        }
        return id
      }, (response) => {
        this.$notify({
          type: 'danger',
          title: 'Falha ao atualizar',
          text: 'Erro cód ' + response.body.error.code + ': ' + response.body.error.message
        })
        return null
      })
    },
    // Deletar
    remover: function (tbl, w, redir, db) {
      var perms = this.$session.get('grupo')
      if (perms) {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID = ' + String(perms) + ')'
          } else {
            w = '(grp_ID = ' + String(perms) + ')'
          }
        }
      } else {
        if (!this.excecoes.includes(tbl)) {
          if (w) {
            w = '(' + w + ') AND (grp_ID IS NULL)'
          } else {
            w = '(grp_ID IS NULL)'
          }
        }
      }
      if (!db) { db = 'prolucro/_table' }
      return this.$http.delete(this.URLApi + db + `/${tbl}?filter=` + encodeURIComponent(w), {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (redir) {
          this.$router.push(redir)
        } else {
          return 1
        }
      }, (response) => {
        if (response.body.error.code === '23000') {
          this.$notify({
            type: 'danger',
            title: 'Falha de dependência ao remover',
            text: 'Erro cód ' + response.body.error.code + ': ' + response.body.error.message
          })
        } else {
          this.$notify({
            type: 'danger',
            title: 'Falha ao remover',
            text: 'Erro cód ' + response.body.error.code + ': ' + response.body.error.message
          })
        }
      })
    },
    // Deletar (sem forçar o grupo)
    removerAdm: function (tbl, w, redir, db) {
      if (!db) { db = 'prolucro/_table' }
      return this.$http.delete(this.URLApi + db + `/${tbl}?filter=` + encodeURIComponent(w), {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': CHAVE_API,
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (redir) {
          this.$router.push(redir)
        } else {
          return 1
        }
      }, (response) => {
        if (response.body.error.code === '23000') {
          this.$notify({
            type: 'danger',
            title: 'Falha de dependência ao remover',
            text: 'Erro cód ' + response.body.error.code + ': ' + response.body.error.message
          })
        } else {
          this.$notify({
            type: 'danger',
            title: 'Falha ao remover',
            text: 'Erro cód ' + response.body.error.code + ': ' + response.body.error.message
          })
        }
      })
    }
  }
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
